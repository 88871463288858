import React from "react";
import ScrollAnimation from "../../hoc/ScrollAnimation";

const AboutSection = () => {
  return (
    <div className={"max-w-6xl p-6 my-24 mx-auto grid md:grid-cols-4"}>
      <div className="">
        <p className="text-sm font-bold">ABOUT US</p>
      </div>
      <div className="col-span-3 mt-4 md:mt-0">
        <ScrollAnimation>
          <p className="text-xl md:text-3xl">
            ALVA PARTNERS LIMITED is a distinguished private limited liability
            company, registered under the laws of the Federal Republic of
            Nigeria - RC number 189502.
          </p>
        </ScrollAnimation>
        <ScrollAnimation>
          <p className={"mt-8 text-sm md:text-sm"}>
            ALVA was established with a vision of delivering tailored business
            solutions. We specialize in helping our clients navigate through the
            intricacies of modern challenges and risks.{" "}
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default AboutSection;
