import React from 'react';
const ProjectBreakdown = ({data}) => {
    return (
        <div  className={'max-w-6xl p-6 my-12 shadow-xl mx-4  md:mx-auto bg-white border border-gray-200 '}>
            <div className="grid  grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-y-4 gap-x-8">
                {
                    data.length>0?data.map((e,i)=>     <div className="text-center">
                        <img src={e.img} alt="kitchen" className={'w-8 h-8 mx-auto '}/>
                        <p className="text-sm mt-2">{e.title}</p>
                    </div>):null
                }
            </div>
        </div>
    );
};

export default ProjectBreakdown;