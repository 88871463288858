import CustomRouter from './routes/customRouter';
import {AnimatePresence} from "framer-motion";

function App() {
  return (
    <AnimatePresence initial={true} mode={"wait"}>
      <CustomRouter/>
    </AnimatePresence>
  );
}

export default App;

