import React from 'react';
import bg from '../../assets/lugbe1.jpg'
const CompanyBreakdown = () => {
    return (
        <div className={'max-w-6xl mx-auto mb-24 mt-16 py-8 px-8 grid gap-6 md:grid-cols-3 text-center'} >
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Integrity</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Integrity</h3>
                  <p className="text-md mt-4 text-blue-50">We believe in conducting our business with honesty, transparency, and ethical standards.
                  </p>
              </div>
            </div>
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Innovation</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Innovation</h3>
                  <p className="text-md mt-4 text-blue-50">We are committed to continuous improvement and development of new and innovative housing solutions.

                  </p>
              </div>
            </div>
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Customer satisfaction</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Customer satisfaction</h3>
                  <p className="text-md mt-4 text-blue-50"> We prioritize our clients' needs and strive to exceed their expectations.

                  </p>
              </div>
            </div>
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Social responsibility</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Social responsibility</h3>
                  <p className="text-md mt-4 text-blue-50"> We are dedicated to giving back to the communities we serve and promoting sustainable development.
                  </p>
              </div>
            </div>
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Teamwork</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Teamwork</h3>
                  <p className="text-md mt-4 text-blue-50">
                   We value collaboration, open communication, and mutual respect among our employees, partners, and stakeholders.
                  </p>
              </div>
            </div>
            <div className="breakdown-card bg p-6" style={{backgroundImage:` linear-gradient( rgba(59,130,246,.3), rgba(59,130,246,6)),url(${bg})`}}>
                <h3 className="text-4xl font-bold text-blue-50">Quality</h3>
              <div className="breakdown-card-abs">
                  <h3 className="text-lg font-bold  text-blue-50">Quality</h3>
                  <p className="text-md mt-4 text-blue-50">
                   We value quality, and put in all the effort to always deliver to our stakeholders.
                  </p>
              </div>
            </div>
        </div>
    );
};

export default CompanyBreakdown;