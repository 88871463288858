import React from "react";
import vision from "../../assets/team/target.jpg";
import vision2 from "../../assets/team/miss.jpg";
import ScrollAnimation from "../../hoc/ScrollAnimation";
const CompanyVisionMission = () => {
  return (
    <div className={""}>
      <div className="max-w-5xl mx-auto mb-24  mt-16 py-8 px-8 border-t-8 border-t-amber-400 bg-blue-950">
        <div className="grid md:grid-cols-2 items-center gap-4 md:gap-8">
          <ScrollAnimation className="">
            <h2 className="text-md flex  items-center text-white">
              {" "}
              <span className={"w-12 h-1 bg-white inline-block"}></span>
              <span>OUR VISION</span>
            </h2>
            <p className="text-sm  mt-2 text-white">
            ALVA was established with a 
vision of delivering tailored business solutions. We specialize in 
helping our clients navigate 
through the intricacies of 
modern challenges and risks.
            </p>
          </ScrollAnimation>
          <div className=" shadow-2xl">
            <img
              src={vision}
              alt="people sitting down"
              className={"w-fit object-cover"}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 items-center mt-4 gap-4 md:gap-8 text-justify">
          <div className=" shadow-2xl hover:shadow-sm">
            <img
              src={vision2}
              alt="people sitting down"
              className={"w-fit object-cover"}
            />
          </div>
          <ScrollAnimation className="">
            <h2 className="text-md flex  items-center text-white">
              {" "}
              <span className={"w-12 h-1 bg-white inline-block"}></span>
              <span>OUR MISSION</span>
            </h2>
            <p className="text-sm  mt-2 text-white">
              We are driven by a singular mission to guide our clients towards
              substantial and enduring enhancements in their performance. To
              achieve this, we take a comprehensive approach, considering both
              external factors in the competitive business landscape and
              internal matters encompassing strategy, project management, and
              human capacity development.
            </p>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default CompanyVisionMission;
