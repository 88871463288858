import React from 'react';
import ScrollAnimation from "../hoc/ScrollAnimation";

const NewsLetter = () => {
    return (
        <div className={' max-w-7xl mx-auto my-24 py-10 px-8 grid  md:grid-cols-2 bg-blue-950'}>
            <ScrollAnimation className="">
                <h4 className="text-2xl font-bold text-white">
                    Join Our NewsLetter
                </h4>
                <p className="text-md mt-4 text-gray-400">Get Updates And Stay Connected - Subscribe To Our Newsletter</p>
            </ScrollAnimation>
            <div className="mt-4 md:mt-0">
                <div className="border border-white px-2 py-2 bg-zinc-100 flex">
                    <input type="text" placeholder={"Enter Your Email"} className={'bg-inherit w-full border-none focus:outline-0'}/>
                    <button className={'bg-amber-500 px-4 py-2 text-center hover:bg-amber-600'}>Subscribe</button>
                </div>
            </div>
        </div>
    );
};

export default NewsLetter;