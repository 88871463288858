import React, { useState } from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";

const Carousel = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="carousel-container">
            <div className="carousel relative">
                <img src={images[activeIndex]} alt="Carousel Slide" className="w-full h-auto" />
                <button
                    className="prev-btn absolute top-1/2 left-2 transform -translate-y-1/2 text-white bg-black/30 hover:bg-black/70 rounded-full p-2"
                    onClick={handlePrev}
                >
                    <ChevronLeftIcon className={'w-6 h-6'}/>
                </button>
                <button
                    className="next-btn absolute top-1/2 right-2 transform -translate-y-1/2 text-white bg-black/30 hover:bg-black/70 rounded-full p-2"
                    onClick={handleNext}
                >
                    <ChevronRightIcon className={'w-6 h-6'}/>
                </button>
            </div>
            <div className="preview-cards flex mt-4 overflow-x-auto">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`preview-card flex-none border border-gray-300 rounded-lg overflow-hidden mx-2 my-2 sm:mx-2 sm:my-2 md:mx-4 md:my-4 ${
                            index === activeIndex ? 'border-blue-500' : ''
                        }`}
                        onClick={() => setActiveIndex(index)}
                    >
                        <img src={image} alt={`Preview Slide ${index}`} className="w-20 h-20 object-cover" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
