import {useEffect} from "react";

export function useSvgScroll() {
    useEffect(()=>{
        let paths  = document.querySelectorAll('.svgScroll');
        let pathData = [];
        for (let i = 0; i < paths.length; i++) {

            let item  = {
                pathLength: paths[i].getTotalLength(),
            }
            pathData.push(item);
            paths[i].style.strokeDasharray = item.pathLength + " " + item.pathLength;
            paths[i].style.strokeDashoffset = item.pathLength;
        }
        const handleScroll = ()=>{
            let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop)/(document.documentElement.scrollHeight - document.documentElement.clientHeight)
            for (let i = 0;i < pathData.length;i++) {
                let drawLength = pathData[i].pathLength * scrollPercentage;
                paths[i].style.strokeDashoffset = pathData[i].pathLength - drawLength;
            }
        }

        window.addEventListener('scroll',handleScroll)
        return ()=>{
            window.removeEventListener('scroll',handleScroll)
        }
    })
}