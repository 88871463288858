import React from 'react'
import  compton from'../../assets/partners/COMPTON-300x64-1.png';
import  pelogo from'../../assets/partners/pelogo.png';
import ScrollAnimation from '../../hoc/ScrollAnimation';
export default function PartnerSection() {
  return (
    <div className='bg-gray-800 px-4 py-20 border-b-[1rem] border-gray-800'>
      <p className='text-center text-gray-200 text-2xl  mb-16'>Our partners</p>
       <ScrollAnimation>
       <div className='max-w-4xl grid gap-8 grid-cols-1 md:grid-cols-2 mx-auto justify-between'>
            <div className='max-w-48 flex justify-center'>
            <img src={compton} className='w-full'/>
            </div>  <div className='max-w-48 flex justify-center'>
            <img src={pelogo} className='object-cover w-full'/>
            </div>
        
       </div>
       </ScrollAnimation>
    </div>
  )
}
