import React from 'react';
import {MapPinIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import slugify from "slugify";
const HouseCard = ({data}) => {
    const navigate = useNavigate()
    function handleNavigate() {
        console.log(data)
        navigate(`/projects/${slugify(data?.title)}`,{state:{
           data
            }})
    }
    return (
        <div className='bg-white z-9 shadow-md hover:shadow-2xl mt-4 max-w-md'>
            {data?.leadImg?<img src={data?.leadImg} alt="Home 1" className={'w-full object-cover'}/>:null}
            <div className="p-4 ">
                <div className="grid grid-cols-2 gap-3 items-center">

                    <div className="w-8 h-8 col-end-1 mr-1">
                        <MapPinIcon className={'w-5 h-5 '}/>
                    </div>
                    <div className=" items-center col-span-2">
                        <h3 className={'text-sm font-bold'}>
                            {data.title}
                        </h3>
                        <p className="text-sm text-gray-400 mt-1">{data?.location}</p>
                     </div>

                </div>


               <div className="flex justify-between border-t border-t-gray-100 my-3">
                   {/*{data?.size.length>0?<div className="flex items-center ">*/}
                   {/*    <svg fill="none" stroke="currentColor" strokeWidth={1.5} className={'w-5 h-5 mr-2'} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">*/}
                   {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />*/}
                   {/*    </svg> <p className="text-sm ml-2 text-gray-400">{data?.size} m<sup>2</sup></p>*/}
                   {/*    </div>:<div></div>}*/}
                   {/*<div className="flex items-center">*/}
                   {/*    <svg xmlns="http://www.w3.org/2000/svg" className={'w-5 h-5'} viewBox="0 0 24 24" fill="currentColor">*/}
                   {/*        <path d="M20 10h-3V7a3 3 0 0 0-3-3H10a3 3 0 0 0-3 3v3H4a2 2 0 0 0-2 2v7h2v-3h16v3h2v-7a2 2 0 0 0-2-2zM9 7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3H9V7zm11 12H4v-5h16v5z"/>*/}
                   {/*    </svg>*/}


                   {/*    <p className="text-sm ml-2 text-gray-400">{data.rooms} Bed</p>*/}
                   {/*</div>*/}
               </div>
                <div className="mt-4 flex justify-between">
                    <button onClick={handleNavigate}  className={`bg-blue-950    py-2 px-4 hover:bg-amber-500 text-white inline-flex text-center`}>
                        LEARN MORE
                    </button>
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className={'w-5 h-5'} viewBox="0 0 24 24" fill="currentColor">
                            <path d="M20 10h-3V7a3 3 0 0 0-3-3H10a3 3 0 0 0-3 3v3H4a2 2 0 0 0-2 2v7h2v-3h16v3h2v-7a2 2 0 0 0-2-2zM9 7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3H9V7zm11 12H4v-5h16v5z"/>
                        </svg>


                        <p className="text-sm ml-2 text-gray-400">{data.rooms} Bed</p>
                    </div>
                    {/*<p className="text-2xl font-bold">N{data?.price}</p>*/}
                </div>
            </div>
        </div>
    );
};

export default HouseCard;