import React from 'react';
import about1 from '../../assets/team/about_img.jpg'
const AboutHero = () => {
    return (
        <div id='hero-section' className={'relative py-8'}>

            <div className={'max-w-6xl mx-auto my-12 px-8 lg:px-2 grid  md:grid-cols-2'}>

                <div className="">
                    <p className="text-lg relative flex items-center text-gray-400"> <span className={"w-12 h-1 bg-gray-400 inline-block"} ></span> <span className={''}>ABOUT US</span></p>
                    <h1 className="text-4xl md:text-4xl lg:text-5xl  font-bold text-white mt-8">
                        <span className={'hover:translate-x-10 duration-300 text-black transition-all ease-slow-ride'}>INTEGRITY.</span>


                        <span className="block md:mt-4 md:ml-4 hover:translate-x-10 duration-300 transition-all ease-slow-ride text-blue-950">INNOVATION.</span>
                        <span className="block md:mt-4 md:ml-8 text-amber-400 hover:translate-x-10 duration-300 transition-all ease-slow-ride">TEAMWORK.</span>
                        <span className="block md:mt-4 md:ml-12 hover:translate-x-10 duration-300 text-black transition-all ease-slow-ride">SUSTAINABILITY.</span>


                    </h1>

                </div>

                <div className="md:mt-0 mt-4">
                    <img src={about1} className={'w-full shadow-amber-600'} alt=""/>
                    <p className="relative py-8 mt-4 inline-block"><span className={'absolute text-6xl -top-2 -left-2 font-bold text-amber-400'}>"</span>
                       <span className="text-2xl text-white " style={{textShadow:"0 2px 8px rgba(0,0,0,.3)"}}>We are here to satisfy all your needs.</span>
                        <span className={'absolute text-6xl -bottom-4 -right-2 font-bold text-amber-400'}>"</span>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default AboutHero;