import React from 'react';
import Carousel from "./Carousel";
import ScrollAnimation from "../../../hoc/ScrollAnimation";
const ProjectImages = ({images}) => {

    return (
        <ScrollAnimation className={'max-w-5xl mx-auto'}>
            <Carousel images={images}/></ScrollAnimation>
    );
};

export default ProjectImages;