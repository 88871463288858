import React from 'react';


const ProjectTop = ({data}) => {
    return (

        <div id='hero-section' className={'relative bg-black py-8 min-h-50'} style={{minHeight:"50vh",maxHeight:"90vh"}}>
            {data?.leadImg?<img alt={''} className="w-full h-full object-cover absolute top-0 left-0" src={data?.leadImg} />:null}

            <div className="absolute inset-0 bg-black opacity-60"></div>
            <div className={'max-w-6xl mx-auto my-24 px-8 lg:px-2 grid text-center absolute absolute top-[40%] left-[50%] -translate-x-2/4 -translate-y-2/4'}>

                <div className="">
                    <h1 className="text-4xl md:text-4xl lg:text-5xl  font-bold text-white mt-8">
                        <span className={'hover:translate-x-10 duration-300 transition-all ease-slow-ride '}>{data?.title}</span>
                        <p className="text-sm text-gray-100 mt-2">{data?.location}</p>

                    </h1>
                </div>


            </div>

        </div>
    );
};

export default ProjectTop;