import {useState} from "react";

import img4 from "../../assets/testImages/redwood.jpg"
import img2 from "../../assets/testImages/001.jpg"
import img3 from "../../assets/testImages/002.jpg"
import img1 from "../../assets/testImages/005.jpg"
import img5 from "../../assets/testImages/006.jpg"
import shawn from "../../assets/testImages/shawn&heaven_gate.jpg"
import shawn1 from "../../assets/testImages/shawn&heaven1.jpg"
import shawn2 from "../../assets/testImages/shawn&heaven2.jpg"

import kitchen from '../../assets/estates/icons8-kitchen-room-100.png'
import gate from '../../assets/estates/icons8-gate-100.png'
import furniture from '../../assets/estates/icons8-furniture-100.png'
import extinguisher from '../../assets/estates/icons8-fire-extinguisher-100.png'
import pool from '../../assets/estates/icons8-pool-100.png'
import cctv from '../../assets/estates/icons8-bullet-camera-100.png'
import security from '../../assets/estates/icons8-security-guard-100.png'
import waterHeater from '../../assets/estates/icons8-water-heater-100.png'
import gym from '../../assets/estates/icons8-treadmill-100.png'
import power from '../../assets/estates/icons8-power-100.png'
import lights from '../../assets/estates/icons8-lights-100.png'
import speaker from '../../assets/estates/icons8-subwoofer-100.png'
import parking from '../../assets/estates/icons8-garage-100.png'
import balcony from '../../assets/estates/icons8-balcony-100.png'
import lounge from '../../assets/estates/icons8-armchair-100.png'
import greenArea from '../../assets/estates/icons8-sprout-100.png'
import wifiIcon from "../../assets/estates/icons8-wi-fi-router-100.png";
import road from "../../assets/estates/icons8-road-100.png";


export function useProjectData() {
    const  [projects] = useState([
        {
            title:"REDWOOD",
            price:"120M",
            meta:[

                {
                    type:"5-Bedroom",
                    pricePerPlot:"120M",
                    totalPlots:"9"
                },
            ],
            leadImg:img2,
            breakdown:[
                {
                    title:"Fully Fitted Kitchen",
                    img:kitchen
                },
                {
                    title:"Swimming Pool",
                    img:pool
                },
                {
                    title:"CCTV",
                    img:cctv
                },
                {
                    title:"Security",
                    img:security
                },
                {
                    title:"Outside Lights",
                    img:lights
                },
                {
                    title:"Gym",
                    img:gym

                },
                {
                    title:"Central Water Heating System",
                    img:waterHeater
                },
                {
                    title:"Uninterrupted power supply",
                    img:power
                },
                {
                    title:"Adequate parking for upto 3 cars",
                    img:parking
                },
                {
                    title:"Inbuilt speakers /sound systems",
                    img:speaker
                },
                {
                    title:"Balconies",
                    img:balcony
                },
                {
                    title:"Family Lounge",
                    img:lounge
                },
                {
                    title:"Ample Green Area or sit out patio",
                    img:greenArea
                },
                {
                    title:"Fibre optic Internet connection",
                    img:wifiIcon
                },
                {
                    title:"Good Road and Drainage",
                    img:road
                }
            ],
            rooms:5,
            size:"",
            images:[
                img1,img2,img3,img4,img5
            ],
            body:
                `<div class='max-w-6xl p-6 my-12 md:my-24 mx-auto grid md:grid-cols-4'>
                    <div class="">
                        <p class="text-sm font-bold">REDWOOD</p>
                    </div>
                    <div class="col-span-3 mt-4 md:mt-0">
                        <p class="text-3xl">Redwood by Sylvastar Residences has a total of 9 units of contemporary 5-bedroom Terrace Town Homes with an excellent
                            neighborhood for your family.
                        </p>
                        <p class='mt-8 text-sm'>In our little community, all Rooms are ensuite with a fully fitted Kitchen and In-built Speakers/Sound system. A very comfortable family lounge, gym, spacious rooms, and a Central water heating system. Each floor comes with a balcony and sit-out patio. The exterior wasn't left behind, as we have secure Estate Outdoor Lights, a swimming pool, and adequate parking space for up to 3 cars.
                        </p>
                        <p class='mt-4 text-sm'> Installed CCTV and Fiber optic internet connection, uninterrupted power supply, drainage system, good road infrastructure and is surrounded by basic social amenities.

                        </p>

                        <ul class='mt-4'>
                            <li class='text-sm'>
                                * The project site is located in Lifecamp. Behind Nizamiye Turkish Hospital. 10 minutes to the Idu train station, 30 minutes to the Nnamdi Azikiwe Airport, and 12 minutes to the Central Business District Abuja.
                            </li>
                            <li class='text-sm mt-2'>
                                * The site covers an area of 2201.65 m2, already mapped out for residential development.</li>
                        </ul>
                    </div>



                </div>`,
            location:"Lifecamp, Abuja."
        },
        {
            title:"Shalena Court.",
            price:"8 - 40M",
            leadImg: shawn,
            meta:[
                {
                    type:"5-Bedroom Detached Duplex (450sqm)",
                    size:"450",
                    pricePerPlot:"8M",
                    totalPlots:"23"
                },
                {
                    type:"Commercial Plot",
                    size:"1300",
                    pricePerPlot:"40M",
                    totalPlots:"1"
                }
            ],
            breakdown:[
                {
                    title:"Security",
                    img:security
                },
                {
                    title:"All rooms ensuite with clothing storage closet and bathroom utilities",
                    img:kitchen
                },
                {
                    title:"Modern water systems and utilities",
                    img:waterHeater
                },
                {
                    title:"Power transformers, electricity distribution, street lights and 24/7 alternative \n" +
                        "electricity",
                    img:power
                },
                {
                    title:"Ample parking space",
                    img:parking
                },
                {
                    title:"Family Lounge",
                    img:lounge
                },
                {
                    title:"Ample Green Area or sit out patio",
                    img:greenArea
                },
                {
                    title:"Estate Gate House / Security posts",
                    img:gate
                },
                {
                    title:"Good Road & Drainage",
                    img:road
                },
                {
                    title:"Fire suppression systems and emergency muster points",
                    img:extinguisher
                }
            ],
            rooms:5,
            size:"450",
            images:[
                shawn,shawn1,shawn2
            ],
            body:
                `<div class='max-w-6xl p-6 my-12 md:my-24 mx-auto grid md:grid-cols-4'>
                    <div class="">
                        <p class="text-sm font-bold">Shalena Court.
</p>
                    </div>
                    <div class="col-span-3 mt-4 md:mt-0">
                        <p class="text-xl md:text-2xl">Shalena Court by Sylvastar Residences is located in Sabon Lugbe, after Trademore estate, Off Airport road, beside Shalom Villa Estate Abuja. It sits on 1.4 hectares and has a total of 23 units of 5-bedroom Detached Duplex and commercial property plots in Shawn & Heaven Estate.

                        </p>
                        <p class='mt-8 text-sm'>Make a 50% down payment and move to the construction site to start working. Get the balance split out over a six-month period.

                        </p>
                        <p class='mt-4 text-sm'> The estate's external road is already finished, while the estate's internal roads have already been paved and made motorable.
Street lighting and other aspects of the estate's electricity have been taken care of, including the provision of two (2) units of 500KVA transformers.
You may start building right now because the necessary building approvals are in place, and the area is livable due to the presence of neighboring residential estates.

                        </p>
                        <div class="space-y-4 my-4">
  <h3 class="text-lg font-bold">Estate Facilities and Infrastructure:</h3>
  <ul class="list-disc ml-8">
    <li>All rooms ensuite with clothing storage closet and bathroom utilities</li>
    <li>Sitting rooms with dining area and a visitors’ restroom with utilities</li>
    <li>Modern water systems and utilities</li>
    <li>Quality floor tiles and interlocks, modern POP, lighting systems, and electrical installations</li>
    <li>Tarred interlocking estate road network, covered drainage systems, breathtaking landscape, perimeter fencing with security wires</li>
    <li>Central water treatment facilities and reticulation system</li>
    <li>Power transformers, electricity distribution, street lights, and 24/7 alternative electricity</li>
    <li>Fire suppression systems and emergency muster points</li>
    <li>Ample parking space</li>
    <li>Estate Gate House / Security posts</li>
  </ul>
</div>

                    </div>
                  



                </div>`,
            location:"Off Airport road, beside Shalom Villa Estate Abuja."
        },
    ])
    return {
     projects
    }
}