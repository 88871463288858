import React from 'react';

const ProjectsHero = () => {
    return (
        <div id='hero-section' className={'relative bg-blue-950 py-8 min-h-50'}>
            <div className={'max-w-6xl mx-auto my-24 px-8 lg:px-2 grid text-center'}>

                <div className="">
                    <h1 className="text-4xl md:text-4xl lg:text-5xl  font-bold text-white mt-8">
                        <span className={'hover:translate-x-10 duration-300 transition-all ease-slow-ride'}>OUR PROJECTS.</span>

                    </h1>
                    <p className="text-md text-white max-w-2xl mx-auto mt-4">Our company provides secure, affordable, and highly rewarding investment opportunities in the Nigerian real estate sector for a wide range of partners.</p>

                </div>


            </div>

        </div>
    );
};

export default ProjectsHero;