import React  from 'react'
import heroImage from '../assets/images/alva_home.jpg'
// import DreamIcon from "../assets/DreamIcon";
import {Link} from "react-router-dom";

;
export default function HeroSection() {

    return (
        <div id='hero-section' className='relative max-w-8xl mx-auto bg-black lg:px-0 min-h-[90vh] p-4 bg-cover' style={{backgroundImage:`url(${heroImage})`}}  >


        
            <div className="absolute inset-0 bg-black opacity-40 bg-cover backdrop-blur-md" ></div>
            <div className='absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-1/4 inset-0 w-full h-auto max-w-7xl grid md:grid-cols-1 '>
                <div className=' md:px-4 px-4 mx-auto max-w-2xl text-center'>
                    <h1 className='text-5xl md:text-5xl lg:text-6xl text-white  font-bold'> World class <span className={'text-amber-400'}>Services</span> at your reach.
                    </h1>
                          <Link to={'/about'} className='bg-blue-950 hover:bg-amber-400 px-2 py-2 mt-10  md:py-4 md:px-8 text-white hover:text-blue-950 inline-flex items-center '>
                        About Us &rarr;
                    </Link>
                </div>
                <div className='mt-48 max-w-3xl mx-auto gap-8 md:gap-4 grid md:grid-cols-2 grid-cols-1  justify-between'> 
                 <span className='text-white border-l-4 px-4 border-blue-500 flex items-center'>
                        <span className='text-3xl font-bold text-gray-300'>32+</span> 
                         <span className="ml-4 text-md">Trusted by company</span>
                        </span>  
                           <span className='text-white border-l-4 px-4 border-blue-500 flex items-center'>
                        <span className='text-3xl font-bold text-gray-300'>18+</span> 
                         <span className="ml-4 text-md">Years experience</span>
                        </span>
               
                </div>
                

            </div>

        </div>
    )
}
