import React from "react";
import serviceImg from "../../assets/service.jpg";
import ScrollAnimation from "../../hoc/ScrollAnimation";
const HomeAbout = () => {
  return (
    <div className={""}>
      <div className="max-w-7xl mx-auto my-24 grid px-4 gap-12 items-center  md:grid-cols-2">
        <ScrollAnimation duration={0.35} className="" style={{ zIndex: 9 }}>
          <img
            src={serviceImg}
            alt="sylvastar"
            className={"w-full object-cover"}
          />
        </ScrollAnimation>
        <ScrollAnimation duration={0.55} className="">
          <p className="text-md relative flex items-center">
            <span className={"w-12 h-1 bg-black inline-block"}></span>
            <span className={""}>OUR SERVICES</span>
          </p>
          <ScrollAnimation>
            <h3 className="text-3xl mt-4 font-bold">Excellence at our core</h3>
          </ScrollAnimation>
          <p className="text-sm  mt-4">
            At ALVA, we offer an array of services, each meticulously designed
            to address the diverse needs of our clients. Our expertise spans the
            following domains
          </p>
          <ul className="mt-4 list-disc list-inside">
            <li>Strategy  and planning</li>
            <li>Business analisys</li>
            <li>Project management</li>
            <li>Procurement consulting</li>
            <li>Market research & analisys</li>
            <li>Risk management consulting</li>
            <li>Fixed income brokerage</li>
            <li>Financial advisory services</li>
            <li>Information Technology & Capacity building</li>
          </ul>

          <button
            className={`bg-blue-950 hover:bg-amber-400 mt-8 py-2 px-4  text-white inline-flex text-center`}
          >
            LEARN MORE
          </button>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default HomeAbout;
