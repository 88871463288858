import React from 'react';
import ProjectsHero from "./projectsHero";
import HouseCard from "../../components/houses/houseCard";
import Footer from "../../components/Footer";
import {useSvgScroll} from "../../hoc/svgScroll";
import NewsLetter from "../../components/NewsLetter";
import Navigation from "../../components/Navigation";
import {useProjectData} from "./projectData";
import useScrollToTop from "../../hoc/scrollToTop";

const Projects = () => {
    useSvgScroll();
    useScrollToTop()
    const {projects} = useProjectData();
    return (
        <div>
            <div className="line_container">
                <svg fill="none"  preserveAspectRatio={"xMidYMax meet"}  viewBox="0 0 84 3114" >
                    <path className={'svgScroll'} d="M42.5 1C42.5 2476.2 42.5 3107.33 42.5 3113.5" stroke="#FFD25E" strokeWidth="10"/>
                    <path className={'svgScroll'} d="M42.5 1C42.5 2000.2 42.5 2500.33 42.5 1900.5" stroke="#010066" strokeWidth="20"/>
                </svg>
            </div>
            <Navigation/>
            <ProjectsHero/>

            <div className="flex flex-col md:flex-row justify-around mt-8 max-w-5xl mx-auto p-4 ">
                {
                    projects.map((e,i)=><HouseCard key={i} data={e}/>)
                }
            </div>
            <NewsLetter/>
            <Footer/>
            
        </div>
    );
};

export default Projects;