import React from 'react';
import Navigation from "../../components/Navigation";

const ScheduleAnInspection = () => {
    return (
        <div>
            <Navigation/>
            <div className="p-4 bg-gray-100 max-w-6xl mx-auto">
                <h6 className="text-2xl font-bold">Schedule an Inspection</h6>
                <iframe title={'Schedule an inspection'}
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeB8FlANJd5Lb3qjM_YHtGHplB3-y6_a36_0wgAlFOpNebwHg/viewform?embedded=true"
                  className={'w-full h-screen'}>Loading…
                </iframe>
            </div>
        </div>
    );
};

export default ScheduleAnInspection;