import React, {useRef, useState} from 'react'
import {Bars3Icon, PhoneIcon, XMarkIcon} from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'
import Logo from '../assets/logo.jpeg'
import {Link} from "react-router-dom";
const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact' },
]

export default function Navigation() {
    const [scrollHeight,setHeight] = React.useState(0);
    const [scrollPosY,setScrollPosY] = React.useState(0);
    const [heroScroll,setHeroScroll] = React.useState(false);
    const [currentColor,setCurrentColor] = React.useState('bg-transparent');
    const [btnColor,setBtnColor] = React.useState('bg-black');

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const hero = document.getElementById('hero-section');

    
    const navRef = useRef(null)
    React.useEffect(()=>{
        setHeight(navRef.current.scrollHeight);
       
      
       function scrollChange (){

            setScrollPosY(window.pageYOffset);

            if (hero) {
                const { height } = hero.getBoundingClientRect();
                setHeroScroll(window.scrollY > height);

                if(window.scrollY > height){ 
                 setCurrentColor('  backdrop-blur-[5px] bg-white/30 border border-gray-100');
                 setBtnColor('bg-blue-700')
                }
                else{
                    if(window.scrollY <=15 ){
                        setCurrentColor('bg-transparent');                  
                        setBtnColor('bg-black');
                        
                    }
                    
                    else{
                        
                        setCurrentColor(' backdrop-blur-[5px] bg-black/30') ;
                    }
                }
              } 
            else {
                setCurrentColor('bg-transparent')
                setBtnColor('bg-black')
            }

        }
        window.addEventListener('scroll',scrollChange);
     return ()=>{
         window.removeEventListener('scroll',scrollChange)
     }
    },[scrollPosY,scrollHeight,hero,heroScroll])
  return (
    <nav ref={navRef} className={` ${currentColor} sticky top-0 left-0 right-0 z-10 `}>
        <div className={`flex justify-between items-center p-2 md:py-4 md:px-8 max-w-7xl transition ease-in mx-auto`}  >
            {/*<div className='flex justify-between items-center gap-8 '>*/}
        <Link to={'/'} className={'mr-4'}>
            <figure>
                <figcaption className='sr-only'>LOGO</figcaption>
                <img src={Logo} className='w-24' alt="sylvastar Logo" />
            </figure>
        </Link>
        <ul className='list-none hidden gap-4 items-center md:flex'>
            {
                navigation.map((e,i)=>( <li key={i + e.name} className={`text-md ${heroScroll?"text-gray-600 hover:text-gray-400":"text-gray-700 hover:text-slate-400"} `}>
                        <Link to={e.href}>{e.name}</Link>
                    </li>
                ))
            }
        </ul>

         {/*</div>*/}
             <div className="flex">
                 <button  className={`${btnColor} border ${heroScroll?"border-none ":" hover:bg-blue-900 "} py-1 px-2 md:py-4 md:px-8 hover:bg-white hover:text-black text-white inline-flex align-baseline`}>
                     <PhoneIcon className='w-4 h-4'/>
                     <span className='text-sm ml-2'>
                     +234 903 118 8451
            </span> <span className='sr-only'>
            +234 903 118 8451
            </span>
                 </button>
                 <button
                     type="button"
                     className="-m-2.5 ml-2 inline-flex md:hidden items-center justify-center rounded-md p-2.5 text-gray-900"
                     onClick={() => setMobileMenuOpen(true)}
                 >
                     <span className="sr-only">Open main menu</span>
                     <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                 </button>
             </div>
        </div>


        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white  px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Alva partners limited </span>
                        <img
                            className="h-8 w-auto"
                            src={Logo}
                            alt=""
                        />
                    </Link>
                    <button
                        type="button"
                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    target={item.target?item.target:"_self"}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <div className="py-6">
                            <button  className={`bg-black border ${heroScroll?"border-none":"border-white hover:bg-blue-900"} py-4 px-8 hover:bg-white hover:text-black text-white inline-flex align-baseline`}>
                                <PhoneIcon className='w-4 h-4'/>
                                <span className='text-sm ml-2'>
                                +234 803 118 8451
            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
       
    </nav>
  )
}
