import React from 'react';
import ProjectTop from "./projectTop";
import ProjectImages from "./projectImages";
import Footer from "../../../components/Footer";
import NewsLetter from "../../../components/NewsLetter";
// import ProjectMap from "./ProjectMap";
import Navigation from "../../../components/Navigation";
import ProjectBreakdown from "./ProjectBreakdown";
import {useLocation} from "react-router-dom";
import useScrollToTop from "../../../hoc/scrollToTop";
import {useSvgScroll} from "../../../hoc/svgScroll";

const Project = () => {
    const {state} = useLocation();
    useScrollToTop();
    useSvgScroll()
    return (
        <div>

            <div className="line_container">
                <svg fill="none" preserveAspectRatio={"xMidYMax meet"}  viewBox="0 0 84 3114" >
                    <path className={'svgScroll'}  d="M42.5 1C42.5 2476.2 42.5 3107.33 42.5 3113.5" stroke="#FFD25E" strokeWidth="10"/>
                    <path className={'svgScroll'}  d="M42.5 1C42.5 2000.2 42.5 2500.33 42.5 1900.5" stroke="#010066" strokeWidth="20"/>
                </svg>
            </div>
            <Navigation/>

            <ProjectTop data={state?.data}/>
            <ProjectBreakdown data={state?.data?.breakdown}/>
            <ProjectImages images={state?.data?.images}/>
            <div dangerouslySetInnerHTML={{__html: state?.data?.body}}/>
            {
                state.data?.meta?<div className={'max-w-6xl mx-auto'}>

                    <div className="border-slate-300 overflow-x-auto  border  mt-8">
                        <table  className="table-auto    min-w-full  divide-gray-200">
                            <thead className={'bg-gray-100  '}>
                            <tr className={'py-4 '}>
                                <th className={' py-5 px-6 text-left text-sm  font-bold text-gray-700 tracking-wider'}> Type</th>
                                {state.data?.meta[0].size?<th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>Size</th>:null}
                                <th className={' py-5 px-6 text-left text-sm font-bold text-gray-700 tracking-wider'}>Price Per Unit</th>
                                <th className={' py-5 px-6 text-left text-sm   font-bold text-gray-700 tracking-wider '}>Total Units</th>
                            </tr>
                            </thead>
                            <tbody className={'divide-y divide-gray-200 tab'}>
                            {
                                state.data.meta.map((ev,j)=>(

                                    <tr key={ev.type + j} className={'hover:bg-gray-100'}>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.type}</td>
                                        {ev?.size?<td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.size}</td>:null}
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.pricePerPlot}</td>
                                        <td className={' py-5 px-6 text-left text-sm  text-gray-700 tracking-wider'}>{ev.totalPlots}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>


                    </div>
                </div>:null
            }
            {/*<ProjectMap/>*/}
            <NewsLetter/>
            <Footer/>
        </div>
    );
};

export default Project;