import React from "react";
import { Routes, Route} from "react-router-dom";
import { Home } from "../pages/home/home";
import Error404 from "../pages/error404/error404";
import About from "../pages/about/about";
import Projects from "../pages/projects/projects";
import Project from "../pages/projects/project/project";
import Contact from "../pages/contact/contact";
import Blog from "../pages/blog/blog";
import ScheduleAnInspection from "../pages/contact/scheduleAnInspection";


export default function CustomRouter() {
    return <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/projects'} element={<Projects />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/blog'} element={<Blog />} />
        <Route path={'/schedule-an-inspection'} element={<ScheduleAnInspection />} />
        <Route path={'/projects/:title'} element={<Project />} />

        <Route path={'/*'} element={<Error404 />} />
    </Routes>
};

