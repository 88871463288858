import React, {useEffect, useRef, useState} from 'react';
import { motion, useAnimation } from 'framer-motion';

const ScrollAnimation = ({ children,xD,yD ,duration}) => {
    const controls = useAnimation();
    const sectionRef = useRef();
    const [inView, setInView] = useState(false);
    const transition = {
        duration: duration?duration:.5,
        ease: [0.6,0.13,0.23,0.96]
    }
    useEffect(() => {
        const sectionNode = sectionRef.current;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            setInView(entry.isIntersecting);
        });

        if (sectionNode) {
            observer.observe(sectionNode);
        }

        return () => {
            if (sectionNode) {
                observer.unobserve(sectionNode);
            }
        };
    }, []);

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={sectionRef}
            initial="hidden"
            animate={controls}
            variants={{
                visible: { opacity: 1, y: 0, },
                hidden: { opacity: 0, y: 150},
            }}
            transition={transition}
        >
            {children}
        </motion.div>
    );
};

export default ScrollAnimation;
