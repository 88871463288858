import React from "react";
import ScrollAnimation from "../../hoc/ScrollAnimation";

const ServicesCard = () => {
  return (
    <div className={" max-w-5xl mx-auto my-12"}>
      <h4 className="text-3xl text-center mb-8 font-bold">
        Our services includes but not limited to
      </h4>
      <div className="grid md:grid-cols-2  gap-4 px-6 py-8 bg-gray-50">
        <ScrollAnimation className="">
          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">BUSINESS ANALYSIS</h3>
              <p className="">
                With a keen eye for detail, we delve into the core of
                businesses, dissecting processes, and providing insights that
                lead to informed decision-making.
              </p>
            </div>
          </div>

          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">PROJECT MANAGEMENT</h3>
              <p className="">
                We take pride in delivering projects on time, within budget, and
                with impeccable quality. Our approach blends efficiency with
                excellence.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">PROCUREMENT CONSULTING</h3>
              <p className="">
                Drawing on extensive industry knowledge, we guide clients in
                optimizing procurement processes, resulting in
                cost-effectiveness and enhanced efficiency
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">
              FIXED
INCOME
BROKERAGE
              </h3>
              <p className="">
              We excel in navigating the
complexities of fixed-income
markets, ensuring our clients achieve 
optimal results in their investment 
strategies. Given our 
knowledge of the financial industry 
and our understanding of your 
needs, we believe that a 
brokerage structure with some 
degree of security while ensuring 
yields are comparable to market 
average is best suited for you.
              </p>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation duration={0.65} className="">
          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">MARKET RESEARCH & ANALYSIS</h3>
              <p className="">
                Our seasoned analysts provide in-depth market research,
                delivering invaluable insights to support informed business
                strategies and investment decisions.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">RISK
MANAGEMENT
CONSULTING</h3>
              <p className="">
              We assess, mitigate, and manage 
risks inherent in business 
operations, safeguarding our client’s 
interests and bolstering their
resilience.
              </p>
            </div>
          </div>

         

          <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">FINANCIAL 
ADVISORY
SERVICES</h3>
              <p className="">
              Our experts offer personalized 
financial advice and planning, 
ensuring our clients make sound 
financial decisions for sustainable 
growth.
              </p>
            </div>
          </div> 
           <div className="flex items-start">
            <div>
              <h3 className="text-md font-bold">INFORMATION
TECHNOLOGY
&
CAPACITY
BUILDING</h3>
              <p className="">
              We leverage cutting-edge 
technology and tailored
training programs to equip 
organizations with the tools 
and knowledge needed to 
thrive in the digital age. </p>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default ServicesCard;
