import React from 'react';
import Navigation from "../../components/Navigation";
import {Link} from "react-router-dom";
import Footer from '../../components/Footer';
const Contact = () => {
    return (
        <div>
            <Navigation/>
            <div className="grid max-w-6xl p-4 md:p-2 mx-auto gap-4 md:grid-cols-2 my-24">
                <div className="">
                    <h6 className="text-2xl font-bold">Contact Info</h6>
                </div>
                <div className="grid md:grid-cols-2 gap-4 ">
                     <div className="p-4 bg-gray-100">
                         <span className={'text-amber-400 font-bold'}>Phone</span>
                         <span className={"block text-sm"}>
                         +234 903 118 8451</span>
                     </div>
                     <div className="p-4 bg-gray-100">
                         <p className={'text-amber-400 font-bold'}>Email</p>
                         <Link to={'mailto: alvapartnersltd@gmail.com'} target={"_blank"} rel={'noreferrer'}  className={"block mt-4 py-4 px-8 bg-blue-950  hover:bg-white hover:text-black text-white inline-flex align-baseline  text-sm"}>

                         Reach Us
                         </Link>
                     </div>
                     <div className="p-4 bg-gray-100">
                         <p className={'text-amber-400 font-bold'}>Address</p>
                         <p   className={" text-sm"}>

                             Head office address: 3rd floor Coscharis building, constituion avenue Abuja, Nigeria
                         </p>
                     </div>
                </div>

            </div>
        <Footer/>
        </div>
    );
};

export default Contact;