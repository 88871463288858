import React from 'react';
import Logo from '../assets/logo.jpeg'
import {AiFillFacebook,AiFillLinkedin,AiOutlineInstagram} from "react-icons/ai";
import './footer.scss'
import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <div className={''}>
            <div className="max-w-7xl mx-auto my-8 py-4 px-8 md:px-8">
                <div className=" md:grid-cols-2 lg:grid-cols-4 gap-4 grid ">
                    <img className={'w-28'} src={Logo} alt={''} />
                    <div className="text-md">
                            <p className="">
                                <span className={'text-gray-500 font-bold'}>Opening Hours</span>
                                <span className={"block text-sm"}>Monday - Friday, 9am - 5pm</span>
                            </p>
                    </div>
                    <div className="text-md">
                            <p className="">
                                <span className={'text-gray-500 font-bold'}>Email</span>
                                <span className={"block text-sm"}>alvapartnersltd@gmail.com</span>
                                <span className={"block text-sm"}>info@alva.com.ng</span>
                            </p>
                            <p className="">
                                <span className={'text-gray-500 font-bold'}>Phone</span>
                                <span className={"block text-sm"}>
                                +234 903 118 8451</span>
                            </p>
                    </div>
                    <div className="text-md">
                            <p className="">
                                <span className={'text-gray-500 font-bold'}>Address</span>
                                <span className={"block text-sm"}>Head office address: 3rd floor Coscharis building, constituion avenue Abuja, Nigeria </span>
                            </p>
                    </div>
                </div>
                <hr className={'mt-8'}/>
                <div className="text-center items-center my-8 grid grid-cols-2 justify-between">
                    <h6 className="text-sm text-gray-400 text-left"> Alva Partners Ltd. Copyright © 2024 All Rights Reserved</h6>

                    <div className="flex justify-end">
                        <Link to={'https://www.linkedin.com/company/alva/'} target={"_blank"} className="social-icon">

                            <AiFillLinkedin className={'text-2xl text-blue-950'}/>
                        </Link>
                        <Link to={'https://web.facebook.com/alva'} target={"_blank"} className="social-icon">
                            <AiFillFacebook className={'text-2xl text-blue-950'}/>
                        </Link>
                        <Link to={'https://www.instagram.com/alva/'} target={"_blank"} className="social-icon">
                            <AiOutlineInstagram className={'text-2xl text-blue-950'}/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;