import React from 'react'
import HeroSection from '../../components/HeroSection'
import HousesSection from "../../components/houses/HousesSection";
import Navigation from "../../components/Navigation";
import HomeAbout from "./homeAbout";
import Footer from "../../components/Footer";
import NewsLetter from "../../components/NewsLetter";
import './home.scss';
import LoyaltySection from "./loyaltySection";
import {useSvgScroll} from "../../hoc/svgScroll";
import useScrollToTop from "../../hoc/scrollToTop";
import ScrollAnimation from '../../hoc/ScrollAnimation';
import CompanyBreakdown from '../about/CompanyBreakdown';
import PartnerSection from './partnerSection';
import AboutSection from '../about/aboutSection';
export const Home = () => {
    useSvgScroll();
    useScrollToTop()
  return (
    <div>
        <div className="line_container">
            <svg fill="none" preserveAspectRatio={"xMidYMax meet"}  viewBox="0 0 84 3114" >
                <path className={'svgScroll '} d="M42.5 1C42.5 2476.2 42.5 3107.33 42.5 3113.5" stroke="#FFD25E"  strokeWidth="10"/>
                <path className={'svgScroll stroke-blue-600'} d="M42.5 1C42.5 2000.2 42.5 2500.33 42.5 1900.5"  strokeWidth="20"/>
            </svg>
        </div>

        <Navigation/>
        <HeroSection/>
        <PartnerSection/>
        <AboutSection/>
        <HomeAbout/>
        <h4 className='text-center text-3xl font-bold'>We are primed to deliver</h4>
        <CompanyBreakdown/>
     
        {/* <LoyaltySection/> */}
        {/* <HousesSection/> */}
        <NewsLetter/>
        <Footer/>

    </div>
  )
}
