import React from 'react';
import AboutHero from "./aboutHero";
import TeamSection from "./TeamSection";
import CompanyVisionMission from "./CompanyVisionMission";
import {useSvgScroll} from "../../hoc/svgScroll";
import Footer from "../../components/Footer";
import NewsLetter from "../../components/NewsLetter";
import CompanyBreakdown from "./CompanyBreakdown";
import './about.scss'
import AboutSection from "./aboutSection";
import Navigation from "../../components/Navigation";
import useScrollToTop from "../../hoc/scrollToTop";
import ServicesCard from "./servicesCard";
const About = () => {
    useSvgScroll()
    useScrollToTop();
    return (
        <div>
            <div className="line_container">
                <svg fill="none" preserveAspectRatio={"xMidYMax meet"}  viewBox="0 0 84 3114" >
                    <path className={'svgScroll'}  d="M42.5 1C42.5 2476.2 42.5 3107.33 42.5 3113.5" stroke="#FFD25E" strokeWidth="10"/>
                    <path className={'svgScroll'}  d="M42.5 1C42.5 2000.2 42.5 2500.33 42.5 1900.5" stroke="#010066" strokeWidth="20"/>
                </svg>
            </div>
            <Navigation/>
            <AboutHero/>
            <AboutSection/>
            <CompanyVisionMission/>
            <ServicesCard/>
            <CompanyBreakdown/>
            {/* <TeamSection/> */}
             <NewsLetter/>
            <Footer/>

        </div>
    );
};

export default About;